/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1230px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  color: #999;
  border-top: 1px solid #e4e4e4;
  font-size: .9rem;
  p {
    margin: 0 0 5px 0;
  }
  a {
    color: #999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  img.bbb {
    margin: 0 0 30px 10px;
  }
}
.font-weight-strong{
  font-weight: 600;
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 74px;
    }
    span.big, span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
      color: $theme-primary;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: .9em;
      font-family: var(--font-family);
      font-weight: 600;
      line-height: 1.4em;
    }
  }
  .dropdown-list{
    clear: right;
    max-width: 300px;
    z-index: 1000;
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      strong {
        font-size: 16px;
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
    }
    a.livechat {
      float: right;
      margin: 0;
      padding: 0 12px 2px 12px;
      font-weight: 400;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:hover{
        background: darken($theme-primary, 7.5%);
      }
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: "\e830";
        color: inherit;
        font-family: icons;
        font-weight: 400;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#banner-wrap {
  background: #f3f7fc;
}
img.seal{
  max-width: 130px;
}
.cta-wrap {
  background: $theme-primary;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  .cta-btn{
    width: 220px;
  }
}
#points-wrap {
  background: #f1f1f1;
  a{
    color: #555;
  }
  #points {
    h4 {
      margin-top: 0;
      &:before{
          display: inline-block;
          margin: 0 10px;
          width: 40px;
          height: 40px;
          content: "";
          background: transparent url(../images/steps-icon.png) no-repeat 0 0/cover;
          vertical-align: middle;
          -webkit-transition: opacity 0.25s ease-out 0.25s;
          transition: opacity 0.25s ease-out 0.25s;
          -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
          margin-bottom: 7px;
      }
      &.step-2:before{
          background-position: -40px 0;
          -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
      }
      &.step-3:before{
          background-position: -80px 0;
          -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
      }
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #points-wrap { 
    #points {
      h4 {
        &:before { 
          display: block; 
          margin: 0 auto 10px auto;
          width: 60px;
          height: 60px; 
        }
        &.step-2:before { background-position: -60px 0; }
        &.step-3:before { background-position: -120px 0; }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) { 
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 75%;
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    #banner {
      background: url(../images/banner-person.png) no-repeat;
      background-position: 0px bottom;
      background-size: 450px;
      h1{
        margin-left: 160px;
      }
      ul {
        font-size: 1.1em;
      } 
    }
  }
  img.seal{
    max-width: 100%;
    width: 200px;
  }
  #points-wrap {
    #points {
      h4 {
        &:before { 
          width: 120px;
          height: 120px; 
        }
        &.step-2:before { background-position: -120px 0; }
        &.step-3:before { background-position: -240px 0; }
      }
    }
  }
}